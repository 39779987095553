<template>
    <div class="px-3 py-2">
        <v-form
            ref="form"
            lazy-validation
        >
        <b-sidebar id="add_offer_jobOrder" aria-labelledby="sidebar-no-header-title" width="900px" no-header backdrop style="direction:ltr" right title="اضافة عميل" shadow >
        <template #default="{ hide }">
            <div class="m-1 text-start" style="direction:rtl">
                <div style="width:130px;margin-right:10px;background:#000;color:#FFF;border-radius:5px" class="btn-primary btn-sm text-center">
                <span>امر تشغيل</span>
                </div>
                <div @click="hide" id="hideAddJob" style="width:100px;text-align:center;float:left;margin-left:5px;margin-top:-25px;cursor:pointer" class="btn-danger btn-sm">
                <span>{{lang.close}}</span>
            </div>
        </div>
            <v-divider></v-divider>
        <div class="px-3 py-2" style="direction:rtl">
            <v-row>
                <v-col cols="12" md="12" sm="12">
                    <v-simple-table>
                        <thead>
                            <tr>
                                <th class="text-center backBlack" style="width:50px;">#</th>
                                <th class="text-center backBlack">الخدمات المقدمة من البرنامج</th>
                                <th class="text-center backBlack" style="width:120px;">الكمية</th>
                                <th class="text-center blackBack" style="width:80px;">الاجراء</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item,index) in tbrows" :key="index">
                                <td class="text-center">{{ parseInt(index) + 1 }}</td>
                                <td class="text-center">{{ item.item_name }}</td>
                                <td class="text-center">{{ item.qty }}</td>
                                <td
                                    style="
                                    cursor: pointer;
                                    width: 85px;
                                    border: 2px solid #bbb !important;
                                    border-top: none !important; 
                                    padding:3px;
                                    text-align: center;
                                    "
                                    class="backRed"
                                >
                                    <div @click="removeItem(index)">
                                        {{lang.delete}}</div>
                                </td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <th></th>
                                <th>
                                    <b-form-input list="itemList" class="inborder" v-model="newrow.item_name" />
                                    <datalist id="itemList">
                                        <option v-for="(item,index) in serviceList" :key="index">{{item.itemcode}} -||- {{ item[`item_name`] }}</option>
                                    </datalist>
                                </th>
                                <th>
                                    <b-form-input class="inborder" v-model="newrow.qty" />
                                </th>
                                <th
                                style="
                                    cursor: pointer;
                                    width: 85px;
                                    border: 2px solid #bbb !important;
                                    border-top: none !important; 
                                    padding:3px;
                                    text-align: center;
                                    "
                                    class="backBlue"
                                >
                                    <div @click="addItem()">
                                        {{lang.add}}</div>
                                </th>
                            </tr>
                        </tfoot>
                    </v-simple-table>
                </v-col>
            </v-row>
        </div>
        </template>
        <template #footer="" class="shadow">
            <div class="d-flex text-light align-items-center px-3 py-2">
                <b-button type="button" @click="addCustomer();" variant="success" class="ma-2" style="width:100px;">
                    {{lang.add}}
                </b-button>
            </div>
      </template>
        </b-sidebar>
        </v-form>
        <vue-snotify></vue-snotify>
      </div>
</template>


<script>
import axios from 'axios';
import {SnotifyPosition} from 'vue-snotify';
export default{
    data(){
        return{
            prog_id: 0,
            tbrows: [],
            serviceList: [],
            newrow: {
                itemcode: '',
                item_name: '',
                qty: 1,
            }
        }
    },
    computed: {
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        headers: function(){
            return [
                { text: "الوصف", value: "city", class:' text-center pb-1 backBlack', style: '' },
                { text: "الكمية", value: "city", class:' text-center pb-1 backBlack', style: 'width:80px;' },
                { text: "السعر", value: "city", class:' text-center pb-1 backBlack', style: 'width:80px;' },
                { text: "الاجمالي", value: "city", class:' text-center pb-1 backBlack', style: 'width:80px;' },
                { text: "الضريبة", value: "city", class:' text-center pb-1 backBlack', style: 'width:80px;' },
                { text: "الاجمالي بعد الضريبة", value: "city", class:' text-center pb-1 backBlack', style: 'width:120px;' },
                { text: "الاجراء", value: "city", class:' text-center pb-1 blackBack', style: 'width:80px;' },
            ]
        },
    }, 
    created(){
        // this.getBranches();
        // this.getStaticid('inDustrial');
        // this.getStaticid('customerSource');
    },
    methods: {
        resetValues(serviceList){
            this.serviceList = serviceList
            this.newrow = {
                itemcode: '',
                item_name: '',
                qty: 1
            }
        },
        removeItem(index) {
            this.tbrows.splice(index,1)
        },
        addItem() {
            this.checkItemCode(this.newrow.item_name)
        },
        checkItemCode(item){
            const itm = item.split(" -||- ")
            if(itm.length > 1){
                let addit = false;
                for(let i=0;i<this.serviceList.length;i++){
                    console.log(this.serviceList[i].itemcode)
                    console.log(itm[0])
                    if(this.serviceList[i].itemcode == itm[0]){
                        addit = true
                    }
                }
                console.log(addit);
                if(addit){
                    this.newrow.itemcode = itm[0];
                    this.newrow.item_name = itm[1]
                    this.tbrows.push(this.newrow)
                    this.newrow = {
                        itemcode: '',
                        item_name: '',
                        qty: 1,
                    }
                }else{
                    let message = "الصنف غير موجود في قائمة الاصناف الخاصة بالبرنامج";
                    this.$snotify.error(message, "تنبيه", {
                        timeout: 1000000,
                        showProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        position: SnotifyPosition.centerCenter,
                        buttons: [
                            {
                                text: this.lang.close, 
                                action: (toast) => {
                                    this.$snotify.remove(toast.id); 
                                } 
                            },
                        ]
                    });
                    this.newrow = {
                        item_code: '',
                        item_name: '',
                        qty: 1
                    }
                    return false;
                }
                
            }else{
                let message = "الصنف غير موجود في قائمة الاصناف الخاصة بالبرنامج";
                this.$snotify.error(message, "تنبيه", {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                this.newrow = {
                    item_code: '',
                    item_name: '',
                    qty: 1
                }
                return false;
            }
        },
        addCustomer(){
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("type", "addOfferJobOrder");
            post.append("auth", this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append("lang", this.$cookies.get(this.$store.state.LangCooki));
            post.append("data[offer]", JSON.stringify(this.tbrows));
            post.append("data[id]", this.prog_id);
            axios.post(this.$store.state.SAMCOTEC.r_path, post).then((response) => {
                let message = "تمت اضافة امر التشغيل بنجاح";
                this.$snotify.error(message, "تنبيه", {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                document.getElementById('hideAddJob').click();
            });
        },
        addIt(id){
            // console.log(this.serviceList);
            if(this.campaign.services.includes(id)){
                const x = this.services.indexOf(id)
                this.campaign.services.splice(x,1)
            }else{
                this.campaign.services.push(id);
            }
        },
        resetAllValues(){
            this.campaign = {
                id: 0,
                city: '',
                campaign_title: '',
                campaign_start: '',
                campaign_price: '',
                campaign_end: '',
                campaign_area: 1, 
                vehicle_type: '',
                car_year: '',
                campaign_target: '',
                campaign_branches: [0],
            }
            this.tbrows = []
            this.newrow = {
                description: '',
                qty: 1,
                price: '',
                total: '',
                vat: '',
                ftotal: ''
            }
        },
        calcNew(){
            const t = this.$calcVat(parseFloat(this.newrow.qty) * parseFloat(this.newrow.price),2);
            this.newrow.total = t.tot
            this.newrow.vat = t.vat
            this.newrow.ftotal = t.ftot
        },
        getItem(id){
            this.getOffers(id)
        },
        getOffers(id){
            let cook = this.$cookies.get(this.$store.state.COOKIEPhase);
            const path = this.$router.currentRoute.path;
            let post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('type','getOffer'); 
            post.append('auth',cook);
            post.append('lang',this.$cookies.get(this.$store.state.LangCooki))
            post.append("data[id]",id) 
            axios.post( 
                this.$SAMCOTEC.r_path, post
            ).then((response) => {
                if(response && response.data && response.data.results && response.data.results.data){
                    this.campaign = response.data.results.data[0]
                }
            })
        },
        getBranches(id){
            let cook = this.$cookies.get(this.$store.state.COOKIEPhase);
            const path = this.$router.currentRoute.path;
            let post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('type','getBranchies'); 
            post.append('auth',cook);
            post.append('lang',this.$cookies.get(this.$store.state.LangCooki))
            post.append("data[id]",id) 
            axios.post( 
                this.$SAMCOTEC.r_path, post
            ).then((response) => {
                if(response && response.data && response.data.results && response.data.results.data){
                    this.branchies = response.data.results.data
                }
            })
        },
        getStaticid(id) {
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("type", "getStaticOptions");
            post.append("auth", this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append("lang", this.$cookies.get(this.$store.state.LangCooki));
            post.append("data[phrase]", id);
            // post.append("data[phrase]", "convertProducts");
            axios.post(this.$store.state.SAMCOTEC.r_path, post).then((response) => {
                // // console.log("i am here",response.data)
                if(id == 'convertProducts')
                    this.optionsS = response.data.results.data;
                else if(id == 'customerSource')
                    this.sources = response.data.results.data
                else if(id == 'inDustrial')
                    this.inDusts = response.data.results.data
                // // console.log("i am here",this.optionsS);
            });
        },
        getItemService() {
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("type", "getItemService");
            post.append("auth", this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append("lang", this.$cookies.get(this.$store.state.LangCooki));
            post.append("data[phrase]", 0);
            // post.append("data[phrase]", "convertProducts");
            axios.post(this.$store.state.SAMCOTEC.r_path, post).then((response) => {
                this.serviceList = response.data.results.data
            });
        },
    },
}
</script>
<style scoped>
.hideIcon {
    display:none;
}
</style>